<template>
  <div class="notFoundPage">
    <h1>404</h1>
    <p>This page does not exists. Really. 🙈</p>
    <br />
    <br />
    <br />
    <br />
    <template>
      <router-link to="/c/vegan-essentials">
        <h1 class="collectionTitle">While you are here check our Vegan Essentials</h1>
      </router-link>
      <ais-index
        :index-name="algoliaIndex"
      >
        <Listing
          collection="vk_veganessentials"
          :displayModal="true"
          :displayTags="false"
          :tag="null"
          :horizontal="false"
          :userLogged="$store.getters.userLoggedIn"
          :perPage="15"
          :infinite="false"
          :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
          :disabledCollections="['vk_icecream']"
          @addItemToCart="$emit('addItemToCart', $event)"
          @openSnackbar="$emit('openSnackbar', $event)"
        />
      </ais-index>
    </template>
  </div>
</template>

<script>

export default {
  name: 'p404',
  metaInfo () {
    return {
      title: 'Not Found'
    }
  },
  data: function () {
    return {
      algoliaIndex: process.env.VUE_APP_ALGOLIA_INDEX
    }
  }
}
</script>

<style scoped lang="stylus">
.notFoundPage {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 10px;

  .collectionTitle {
    font-family: 'Bebas Neue', sans-serif;
  }
}
</style>
